<template>
  <div class="more">
    <p>
      {{ truncatedText }} &nbsp;
      <span :class="`add ${show}`" @click="toggle">{{
        truncatedText.length < 80
          ? (show = "hide")
          : isExpanded
          ? $t("less")
          : $t("more")
      }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      isExpanded: false,
      show: "show",
    };
  },
  computed: {
    truncatedText() {
      return this.isExpanded
        ? this.text
        : this.text.slice(0, this.maxLength) +
            (this.text.length > this.maxLength ? "..." : "");
    },
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.more {
  position: relative;
  p {
    word-break: keep-all;
    color: #c1c1c1;
    font-size: 10px;
    margin-top: 0.8em;
    .add {
      color: white;
      font-size: 10px;
      cursor: pointer;
    }
    .show {
      display: initial;
    }
    .hide {
      display: none;
    }
  }
}
</style>
