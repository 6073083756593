export const superCute = {
  title: "The - Super Cutie",
  description:
    "A family of baby snails and their siblings live very happily in a secluded house in the mountains.",
  detailedDescription:
    "This series explores the advancement of robotics and how autonomous robots will change the future.",
  genre: "Animation",
  director: "Jin Do",
  writer: "Toto Kim",
  cast: "Toto Kim, Jin Do, Toto Lee",
  summary: "I-Star, 2016~2024",
  episodes: [
    {
      name: "Episode 1: The Arrival of Rex",
      description:
        "A new kind of robot named Rex challenges existing technology.",
    },
    // More episodes...
  ],
};
