import { createI18n } from "vue-i18n";
import { en } from "./en";
import { ko } from "./ko";

const messages = {
  en,
  ko,
};

const i18n = createI18n({
  locale: "ko", // Default locale
  fallbackLocale: "en",
  messages,
});

export default i18n;
