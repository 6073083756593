import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import BootstrapVue3 from "bootstrap-vue-next";
import { BCarousel, BCarouselSlide } from "bootstrap-vue-next";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "./assets/scss/main.scss";

import { web3, turboplayContract, tokenContract } from "./utils/web3";

const app = createApp(App);

//인스턴스를 전역 속성으로 설정하여,
//모든 컴포넌트에서 this.$web3 및 this.$contract를 통해 Web3 인스턴스와 컨트랙트 인스턴스에 접근 가능
app.config.globalProperties.$web3 = web3;
app.config.globalProperties.$contract = turboplayContract;
app.config.globalProperties.$tokenContract = tokenContract;

app.use(router);
app.use(store);
app.use(i18n);
app.use(
  BootstrapVue3({
    plugins: {
      modalController: true,
    },
  })
);
app.component("b-carousel", BCarousel);
app.component("b-carousel-slide", BCarouselSlide);

app.mount("#app");
