import { tokenContract } from "./index";

const DECIMALS = parseInt(process.env.VUE_APP_TOKEN_DECIMALS, 10);
const TOKEN_SYMBOL = process.env.VUE_APP_TOKEN_SYMBOL;

export async function getTokenBalance(address) {
  try {
    const balance = await tokenContract.methods.balanceOf(address).call();
    // console.log("balance", balance);
    const balanceNumber = balance / BigInt(Math.pow(10, DECIMALS));

    return balanceNumber;
  } catch (error) {
    console.error("Failed to fetch token balance");
  }
}

export async function addTokenToMetamask() {
  try {
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: process.env.VUE_APP_TOKEN_CONTRACT_ADDRESS,
          symbol: TOKEN_SYMBOL,
          decimals: DECIMALS,
        },
      },
    });
  } catch (error) {
    console.error("Failed to add token to MetaMask", error);
  }
}
