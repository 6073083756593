export const anotherbot = {
  title: "The Gun Bot",
  description: "An in-depth look at autonomous robots.",
  detailedDescription:
    "This series explores the advancements in robotics and how autonomous robots are changing the future.",
  genre: "Animation",
  director: "Jane Doe",
  writer: "John Smith",
  cast: ["Actor One", "Actor Two"],
  summary: "Asia Star, 2016~2024",
  rating: "ALL",
  episodes: [
    {
      name: "Episode 1: The Rise of Rex",
      description: "Rex, a new kind of robot, challenges existing technology.",
    },
    // More episodes...
  ],
};
