<template>
  <div class="loading" v-if="loading">
    <LoadingModal />
  </div>
  <div class="episode-list">
    <div class="alert" v-if="!account">
      <p>{{ $t("connectWalletMessage") }}</p>
    </div>

    <div v-else>
      <div
        v-for="(episode, i) in episodes"
        :key="episode.video_id"
        class="episode"
      >
        <div>
          <div class="thumbnail">
            <iframe
              :src="`https://player.vimeo.com/video/${episode.video_id}`"
              width="100%"
              height="100%"
              frameborder="0"
              allow="fullscreen; picture-in-picture"
              @load="setupVimeoPlayer(episode.video_id)"
            ></iframe>
          </div>
          <div class="episode-info">
            <h3>{{ $t(`${content.id}.episodes[${i}].name`) }}</h3>
            <div class="time">
              <p class="me-4">
                {{ formatDuration(episode.duration) }}
              </p>

              <p>
                {{ formatDate(episode.created_time) }}
              </p>
              <RewardB class="small" :episode="episode" />
            </div>
            <RewardB class="big" :episode="episode" />
            <p class="des">
              {{ $t(`${content.id}.episodes[${i}].description`) }}
            </p>
          </div>
        </div>
        <MoreLess
          class="des1"
          :text="$t(`${content.id}.episodes[${i}].description`)"
          :maxLength="80"
        />
      </div>
    </div>

    <b-modal
      id="reward-modal"
      title=""
      ok-only
      v-model="modalVisible"
      :ok-title="modalButtonText"
      @ok="modalVisible = false"
    >
      <p>{{ modalMessage }}</p>
      <div v-if="modalTxHash">
        <span>Tx:&nbsp;</span>
        <a :href="modalTxUrl" target="_blank"> {{ modalTxHash }}</a>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue-next";
import RewardB from "@/components/RewardB.vue";
import MoreLess from "@/components/MoreLess.vue";
import LoadingModal from "@/components/LoadingModal.vue";

import axios from "@/utils/axios";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    BModal,
    RewardB,
    MoreLess,
    LoadingModal,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    episodes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      modalVisible: false,
      modalMessage: "",
      modalButtonText: "Close",
      modalTxHash: "",
      modalTxUrl: "",
    };
  },
  computed: {
    ...mapState(["account", "rewardVideoIds", "tokenBalance"]),
  },
  methods: {
    ...mapMutations(["addTokenBalance", "addRewardVideoId", "addTotalRewards"]),
    formatDuration(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      // console.log(this.$i18n.locale);
      if (this.$i18n.locale == "ko") {
        if (hrs > 0) {
          return `${hrs}시간 ${mins}분 ${secs}초`;
        } else if (mins > 0) {
          return `${mins}분 ${secs}초`;
        } else {
          return `${secs}초`;
        }
      } else {
        if (hrs > 0) {
          return `${hrs}h ${mins}m ${secs}s`;
        } else if (mins > 0) {
          return `${mins}m ${secs}s`;
        } else {
          return `${secs}s`;
        }
      }
    },
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      };
      return new Date(dateString).toLocaleDateString(
        this.$i18n.locale,
        options
      );
    },
    setupVimeoPlayer(videoId) {
      const iframe = document.querySelector(`iframe[src*="${videoId}"]`);
      if (!iframe) return;

      const player = new window.Vimeo.Player(iframe);

      // const defaultSpeed = 1.0; // 기본 재생 속도
      let maxTime = 0; // 사용자가 시청한 최대 시간

      // player.setPlaybackRate(defaultSpeed); // 초기 재생 속도 설정

      // 기존 이벤트 리스너 제거
      // player.off("playbackratechange");
      player.off("timeupdate");
      player.off("ended");

      // 재생 속도 변경 이벤트 감지
      // player.on("playbackratechange", (data) => {
      //   if (data.playbackRate !== defaultSpeed) {
      //     alert("재생 속도 변경 불가");
      //     player.setPlaybackRate(defaultSpeed).catch((error) => {
      //       // 재생 속도 변경 실패 처리
      //       console.log(error);
      //     });
      //   }
      // });

      player.on("timeupdate", (data) => {
        if (data.seconds > maxTime + 1) {
          // 1초 이상 더 나간 것을 감지
          player.setCurrentTime(maxTime).catch((error) => {
            // 시간 조정 실패 처리
            if (error.name === "RangeError") {
              // 설정하려는 시간이 비디오의 범위를 벗어났을 때
            }
          });
        } else {
          // 정상적인 재생 또는 잠시 후로 갔을 경우, 최대 시간 업데이트
          maxTime = Math.max(maxTime, data.seconds);
        }
      });

      // 영상이 끝까지 재생되었을 때
      player.on("ended", () => {
        // console.log("시청완료");
        if (this.isRewardClaimed(videoId)) return;
        this.loading = true;
        this.claimReward(videoId);
        // this.claimReward("12345678912"); //테스트
      });
    },
    async claimReward(videoId) {
      // console.log("claimReward videoId", videoId);
      try {
        const response = await axios.post("/api/claimReward", {
          user: this.account,
          videoId,
        });
        const formattedTx = await this.formattedTx(response.data.txHash);
        // console.log("claimReward formattedTx", formattedTx);

        if (response.data.success) {
          // 리워드 수령 성공 시
          const reward = this.episodes.find(
            (episode) => episode.video_id === videoId
          ).reward;
          // console.log("claimReward reward", reward);

          this.addTokenBalance(Number(reward));
          this.addTotalRewards(Number(reward));
          this.addRewardVideoId(videoId);
          this.modalMessage = "리워드 수령 성공";
          this.modalTxHash = formattedTx;
          this.modalTxUrl = `https://bscscan.com/tx/${response.data.txHash}`;
          this.modalButtonText = "Close";
        } else {
          // 리워드 수령 실패 시
          this.modalMessage = "리워드 수령 실패";
          this.modalTxHash = formattedTx;
          this.modalTxUrl = `https://bscscan.com/tx/${response.data.txHash}`;
          this.modalButtonText = "Close";
        }
      } catch (error) {
        console.error("Failed to claim reward:", error);
        this.modalMessage = "Error: Failed to claim reward.";
        this.modalTxHash = "";
        this.modalTxUrl = "";
        this.modalButtonText = "Close";
      } finally {
        this.loading = false;
        this.modalVisible = true;
      }
    },
    async formattedTx(tx) {
      const start = tx.substring(0, 4);
      const end = tx.substring(tx.length - 4);
      return `${start}...${end}`;
    },
    isRewardClaimed(videoId) {
      return this.rewardVideoIds.includes(videoId);
    },
  },
};
</script>

<style lang="scss" scoped>
.episode-list {
  .alert {
    height: 15em;
    display: grid;
    place-items: center;
    font-size: 24px;
    color: #c1c1c1;
  }
  .episode {
    padding: 4em 0 4em;
    border-bottom: 1px solid #c1c1c1;
    &:last-child {
      border-bottom: none;
    }
    div {
      display: flex;
      justify-content: space-between;

      .thumbnail {
        width: 50%;
        aspect-ratio: 16 / 9;
        cursor: pointer;
        align-items: center;
        display: flex;
        iframe {
          border-radius: 2em;
        }
      }

      .episode-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 45%;
        h3 {
          font-size: 32px;
          font-weight: 600;
        }
        .time {
          display: flex;
          justify-content: normal;
          font-size: 24px;
          font-weight: 500;
          color: #c1c1c1;
          margin-bottom: 0.5em;
          .small {
            display: none;
          }
        }

        .des {
          font-size: 20px;
          word-break: keep-all;
        }
      }
    }
    .des1 {
      display: none;
    }
  }
}
@media (max-width: 991px) {
  .episode-list {
    position: relative;
    border-top: 1px solid white;

    .episode {
      padding: 1em 0;
      // border-bottom: none;
      display: block;
      div {
        display: block;
        .thumbnail {
          width: 100%;
          iframe {
            border-radius: 0.3em;
          }
        }
        .episode-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 100%;
          h3 {
            padding-top: 1em;
            margin-bottom: 0;
            font-size: 12px;
          }
          .time {
            font-size: 12px;
            margin-bottom: 0;
            align-items: flex-end;
            .me-4 {
              display: none;
            }
            .small {
              margin-left: 1em;
            }
          }
          .big {
            display: none !important;
          }
          .des {
            display: none;
          }
        }
      }
      .des1 {
        display: inline;
      }
    }
    .alert {
      font-size: 12px;
    }
  }
}
</style>
