export const superCute = {
  title: "더 - 슈퍼 깜찍이",
  description:
    "아기 달팽이 5남매와 달팽이 가족은 산속 외딴집에서 아주 행복하게 살고 있었다.",
  detailedDescription:
    "이 시리즈는 로봇 공학의 발전과 자율 로봇이 미래를 어떻게 변화시키는지를 탐구합니다.",
  genre: "애니메이션",
  director: "도진",
  writer: "김또또",
  cast: "김또또, 도진, 이또또",
  summary: "아이사 스타, 2016~2024",
  episodes: [
    {
      name: "1화: 렉스의 등장",
      description: "렉스라는 새로운 종류의 로봇이 기존 기술에 도전합니다.",
    },
    // More episodes...
  ],
};
