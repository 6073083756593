export const capola = {
  title: "케플러 행성 - 모선아크",
  description:
    "500 광년이나 먼 케플러 행성에서 지구를 침공하기 위해 출격하는 모습",
  detailedDescription:
    "이 시리즈는 로봇 공학의 발전과 자율 로봇이 미래를 어떻게 변화시키는지를 탐구합니다.",
  genre: "애니메이션",
  director: "도진",
  writer: "김또또",
  cast: "김또또, 도진, 이또또",
  summary: "아이사 스타, 2016~2024",
  episodes: [
    {
      name: "1화: 렉스의 등장",
      description: "렉스라는 새로운 종류의 로봇이 기존 기술에 도전합니다.",
    },
    // More episodes...
  ],
};
