import { defaultWagmiConfig } from "@web3modal/wagmi/vue";

import { bsc } from "viem/chains";

export const projectId = process.env.VUE_APP_PROJECT_ID;

// 2. Create wagmiConfig
const metadata = {
  name: "Connect modal",
  description: "Turbo",
  url: process.env.VUE_APP_BASE_URL, // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bsc];
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  // ...wagmiOptions // Optional - Override createConfig parameters
});
