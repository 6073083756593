import { greatQbot } from "./greatQbot";
import { anotherbot } from "./anotherbot";
import { superCute } from "./superCute";
import { capola } from "./capola";

export const animations = {
  greatQbot,
  anotherbot,
  superCute,
  capola,
  // Other animations...
};
