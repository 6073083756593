<template>
  <footer :class="{ 'black-bg': color === 'black' }">
    <div class="wrapper container">
      <p>{{ $t("footerText") }}{{ $t("footerText1") }}</p>
      <p>
        {{ $t("footerText2") }}
      </p>
      <p>COPYRIGHT&copy; {{ $t("footerText3") }}</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.black-bg {
  background-color: black;
  .wrapper {
    p {
      color: white;
    }
  }
}
footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.26);
  width: 100vw;
  height: 8.5em;
  margin-left: calc(50% - 50vw);
  .wrapper {
    text-align: end;

    p {
      color: #636363;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

@media (max-width: 991px) {
  footer {
    .wrapper {
      p {
        font-size: 12px;
        word-break: keep-all;
      }
    }
  }
}
</style>
