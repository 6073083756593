import axios from "axios";

const client = axios.create({
  baseURL: "https://api.vimeo.com",
  headers: {
    Authorization: `bearer ${process.env.VUE_APP_VIMEO_ACCESS_TOKEN}`,
  },
});

export async function fetchVideos(page = 1, videos = []) {
  try {
    const response = await client.get(`me/videos`, {
      params: {
        page,
        per_page: 50, // 한 페이지당 동영상 수, 최대 50
      },
    });

    videos = videos.concat(response.data.data);
    // console.log("fetchVideos response:", videos);

    // 다음 페이지가 있으면 계속 불러오기
    if (response.data.paging && response.data.paging.next) {
      return fetchVideos(page + 1, videos);
    } else {
      return videos;
    }
  } catch (error) {
    console.error("Error fetching Vimeo videos:", error);
    return 0;
  }
}

export async function getViews() {
  const videos = await fetchVideos();
  let views = 0;

  for (let video of videos) {
    views += video.stats.plays; // 전체 시청 시간에 추가
  }

  return views;
}

export default client;
