<template>
  <div>
    <div
      class="spinner-border text-light"
      style="width: 4rem; height: 4rem"
      role="status"
    >
      <span class="sr-only"></span>
    </div>
    <div class="msg">Loading...</div>
  </div>
</template>
<script>
export default {
  name: "LoadingModal",
};
</script>
