import { animations } from "./animations";
// import { movies } from "./movies";
// import { documentaries } from "./documentaries";
// import { events } from "./events";
export const ko = {
  ...animations,
  //   movies,
  //   documentaries,
  //   events,
  // Basic translations
  logo: "TURBO PLAYHOUSE",
  w2e: "W2E(Watch to Earn)서비스 플랫폼",
  claimReward:
    "다양한 영상을 시청하고 터보체인 (TBC) 리워드 토큰을 받아보세요!",
  connectWallet: "지갑 연결",
  tbc: "TBC",
  language: "언어",
  animation: "TV 애니메이션",
  movie: "영화",
  game: "게임",
  documentary: "다큐",
  event: "이벤트",
  views: "총 조회수",
  accumulatedRewards: "누적 리워드",
  highQualityContent: "다양한 고품질 콘텐츠",
  tradeOnDIGIFINEX: "DIGIFINEX 거래하기",
  tradeOnXT: "XT 거래하기",
  tradeRewards: "리워드는 언제든 거래소에서 간편하게 거래 및 출금",
  community: "커뮤니티",
  telegram: "텔레그램",
  aboutUs: "정보",
  projectHomepage: "터보체인",
  foundationHomepage: "아시아스타",
  whitepaper: "백서",
  footerText: "㈜아시아스타 엔터테인먼트 ㅣ 대표: 엄영식",
  footerText1:
    " ㅣ 사업자등록번호: 214-88-76261 ㅣ 주소:서울특별시 구로구 디지털로 26길 111, 1308호",
  footerText2: " TEL:070-4285-1680",
  footerText3: "(주)아시아스타 엔터테인먼트 All rights reserved",
  watchNow: "바로 시청하기",
  moreDetails: "상세정보 보기",
  episodeList: "에피소드",
  contentInfo: "상세 정보",
  description: "설명",
  detailedDescription: "자세한 설명",
  genre: "장르",
  director: "감독",
  writer: "작가",
  cast: "출연",
  network: "방송사",
  rating: "등급",
  reward: "리워드",
  minutes: "분",
  aired: "방송 시간",
  title: "제목",
  summary: "개요",
  connectWalletMessage: "먼저 지갑연결을 진행해 주세요!",
  rewardClaimed: "수령 완료",
  more: "더보기",
  less: "간략히",
};
