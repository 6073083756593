<template>
  <div class="content-info">
    <div class="detailed-description">
      {{ $t(`${content.id}.detailedDescription`) }}
    </div>
    <div class="additional-info">
      <img
        :src="require(`@/assets/img/${content.id}/poster-s.png`)"
        alt="Poster"
        class="poster"
      />
      <div class="details">
        <h3>{{ $t(`${content.id}.title`) }}</h3>
        <div class="detail-row">
          <span class="label">{{ $t("summary") }}:</span>
          <span class="value">{{ $t(`${content.id}.summary`) }}</span>
        </div>
        <div class="detail-row">
          <span class="label">{{ $t("genre") }}:</span>
          <span class="value">{{ $t(`${content.id}.genre`) }}</span>
        </div>

        <div class="detail-row">
          <span class="label">{{ $t("cast") }}:</span>
          <span class="value">{{ $t(`${content.id}.cast`) }}</span>
        </div>

        <div class="detail-row">
          <span class="label">{{ $t("director") }}:</span>
          <span class="value">{{ $t(`${content.id}.director`) }}</span>
        </div>

        <div class="detail-row">
          <span class="label">{{ $t("writer") }}:</span>
          <span class="value">{{ $t(`${content.id}.writer`) }}</span>
        </div>

        <div class="detail-row">
          <span class="label">{{ $t("rating") }}:</span>
          <img
            :src="require(`@/assets/img/rating/${content.rating}.png`)"
            alt="rating"
            class="value"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content-info {
  color: white;
  padding: 2em 0 2em;
  font-size: 20px;
  word-break: keep-all;
  .detailed-description {
    margin-bottom: 1em;
  }
  .additional-info {
    display: flex;
    .poster {
      width: 25%;
      height: auto;
      margin-right: 3em;
      border-radius: 25px;
    }
    .details {
      display: flex;
      flex-direction: column;
      padding: 1em 0 1em;
      h3 {
        margin-bottom: 0.5em;
        font-size: 32px;
        font-weight: 600;
      }
      .detail-row {
        margin-bottom: 10px;
        font-size: 24px;

        .label {
          font-weight: 600;
        }
        .value {
          margin-left: 1em;
          color: #c1c1c1;
        }
        img {
          height: 2em;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .content-info {
    color: white;
    padding: 1em 0;
    font-size: 20px;
    word-break: keep-all;
    position: relative;
    border-top: 1px solid white;
    .detailed-description {
      font-size: 12px;
    }

    .additional-info {
      display: flex;
      .poster {
        width: 35%;
        height: auto;
        margin-right: 1em;
        border-radius: 15px;
      }
      .details {
        display: flex;
        flex-direction: column;
        padding: 0;
        h3 {
          display: none;
        }
        .detail-row {
          margin-bottom: 10px;
          font-size: 12px;

          .label {
            font-weight: 600;
          }
          .value {
            margin-left: 1em;
            color: #c1c1c1;
          }
          img {
            height: 2em;
          }
        }
      }
    }
  }
}
</style>
