<template>
  <div class="bg">
    <div class="container">
      <section class="banner">
        <img :src="require(`@/assets/img/${content.id}/poster.png`)" alt="" />
        <img
          class="h-100"
          :src="require(`@/assets/img/black-shadow.png`)"
          alt=""
        />
        <div class="wrapper">
          <h1>{{ $t(`${content.id}.title`) }}</h1>
          <p>{{ $t(`${content.id}.description`) }}</p>
          <button
            class="btn btn-primary"
            @click="setActiveComponent('ContentInfo')"
          >
            {{ $t("moreDetails") }}
          </button>
        </div>
      </section>
      <section class="list">
        <div class="tap-container">
          <div
            class="tap-item"
            :class="{ active: activeComponent === 'EpisodeList' }"
            @click="setActiveComponent('EpisodeList')"
          >
            {{ $t("episodeList") }}
          </div>
          <div
            class="tap-item"
            :class="{ active: activeComponent === 'ContentInfo' }"
            @click="setActiveComponent('ContentInfo')"
          >
            {{ $t("contentInfo") }}
          </div>
        </div>

        <component
          :is="activeComponent"
          :content="content"
          :episodes="content.episodes"
        ></component>
      </section>

      <AppFooter color="black"></AppFooter>
    </div>
  </div>
</template>

<script>
import { animations } from "@/data/animations";
import { movies } from "@/data/movies";
import { documentaries } from "@/data/documentaries";
import { events } from "@/data/events";
import ContentInfo from "@/components/ContentInfo.vue";
import EpisodeList from "@/components/EpisodeList.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "ContentDetail",
  components: {
    ContentInfo,
    EpisodeList,
    AppFooter,
  },
  data() {
    return {
      content: {},
      activeComponent: "EpisodeList", // 초기 컴포넌트 설정
      previousContentId: null, // 이전 콘텐츠 ID를 저장하기 위한 상태
    };
  },
  created() {
    this.loadContent();
  },
  watch: {
    "$route.params.id": {
      // 'id' 변경을 직접 감시
      immediate: true,
      handler(newId, oldId) {
        if (newId !== oldId) this.loadContent();
      },
    },
  },
  methods: {
    loadContent() {
      const { type, id } = this.$route.params;
      let contentList = [];

      switch (type) {
        case "animation":
          contentList = animations;
          break;
        case "movie":
          contentList = movies;
          break;
        case "documentary":
          contentList = documentaries;
          break;
        case "event":
          contentList = events;
          break;
        default:
          break;
      }

      const newContent = contentList.find((content) => content.id === id) || {};
      // 콘텐츠가 변경되면 activeComponent를 "EpisodeList"로 설정
      if (newContent && this.previousContentId !== id) {
        this.content = newContent;
        this.activeComponent = "EpisodeList";
        this.previousContentId = id;
      }
    },
    setActiveComponent(componentName) {
      this.activeComponent = componentName;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #1b1b1b;
  color: white;
  .banner {
    position: relative;
    aspect-ratio: 1.69 /1;
    overflow: hidden;

    img {
      width: 100%;
      position: absolute;
    }
    .wrapper {
      position: absolute;
      left: 3em;
      bottom: 3em;

      h1 {
        font-size: 36px;
        font-weight: 700;
      }
      p {
        font-size: 24px;
        font-weight: 500;
        color: #c1c1c1;
      }
      .btn {
        font-size: 24px;
        font-weight: 700;
        width: 12em;
        line-height: 1.5em;
        margin-top: 1em;
      }
    }
  }
  .tap-container {
    display: flex;
    font-size: 32px;
    font-weight: 600;
    padding: 1.5em 0 0.5em;
    border-bottom: 1px solid #c1c1c1;
    .tap-item {
      color: #c1c1c1;

      cursor: pointer;
      position: relative;
      margin-right: 3rem;
      &.active {
        color: white;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: -0.55em;
          height: 3px;
          background-color: #0093ba;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .bg {
    .container {
      padding-left: 0;
      padding-right: 0;
      .banner {
        img {
          width: 100%;
        }
        .wrapper {
          left: 1em;
          bottom: 1em;
          padding: 0 4em 0 1.5em;
          h1 {
            font-size: 20px;
            font-weight: 700;
          }
          p {
            font-size: 12px;
            font-weight: 500;
            color: white;
          }
          .btn {
            display: none;
          }
        }
      }
      .list {
        padding: 0 2em;
        .tap-container {
          display: flex;
          font-size: 14px;
          padding: 1.5em 0 0.5em;
          border-bottom: none;
          .tap-item {
            margin-left: 1em;
            margin-right: 1rem;
            &.active {
              &::after {
                left: -1em;
                width: 75px;
                z-index: 1;
                bottom: -0.65em;
                height: 4px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
