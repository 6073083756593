import { createStore } from "vuex";
import { getViews } from "@/utils/vimeoClient";
import { getTokenBalance } from "@/utils/web3/metamask";
import { turboplayContract } from "@/utils/web3";
const DECIMALS = parseInt(process.env.VUE_APP_TOKEN_DECIMALS, 10);

export default createStore({
  state: {
    views: 0,
    viewsFetched: false,
    totalRewards: 0,
    totalRewardsFetched: false,
    account: null,
    rewardVideoIds: [],
    tokenBalance: 0,
  },
  mutations: {
    setAccount(state, account) {
      state.account = account;
    },
    setTokenBalance(state, tokenBalance) {
      state.tokenBalance = tokenBalance;
    },
    addTokenBalance(state, amount) {
      state.tokenBalance += BigInt(amount);
      // console.log("addTokenBalance tokenBalance:", state.tokenBalance);
    },
    setViews(state, views) {
      state.views = views;
      state.viewsFetched = true;
    },
    setRewardVideoIds(state, rewardVideoIds) {
      state.rewardVideoIds = rewardVideoIds;
    },
    addRewardVideoId(state, videoId) {
      state.rewardVideoIds.push(videoId);
      // console.log("addRewardVideoId rewardVideoIds:", state.rewardVideoIds);
    },
    setTotalRewards(state, totalRewards) {
      state.totalRewards = totalRewards;
      state.totalRewardsFetched = true;
    },
    addTotalRewards(state, amount) {
      state.totalRewards += BigInt(amount);
      // console.log("addTotalRewards totalRewards:", state.totalRewards);
    },
  },
  actions: {
    async fetchViews({ commit, state }) {
      if (state.viewsFetched) return;

      const views = await getViews();
      commit("setViews", views);
    },

    async connectWallet({ commit, dispatch }, account) {
      if (account) {
        commit("setAccount", account);
        const tokenBalance = await getTokenBalance(account);
        commit("setTokenBalance", tokenBalance);
        dispatch("fetchBasicInfo", account);
      }
    },

    async fetchBasicInfo({ commit }, account) {
      const data = await turboplayContract.methods.getBasicInfo(account).call();
      const rewardVideoIds = data.filter((id) => id != 0);
      // 숫자를 문자열로 변환
      const rewardVideoIdsString = rewardVideoIds.map((VideoId) =>
        VideoId.toString()
      );
      // console.log("fetchBasicInfo rewardVideoIdsString:", rewardVideoIdsString);

      commit("setRewardVideoIds", rewardVideoIdsString);
    },
    async fetchTotalRewards({ commit, state }) {
      if (state.totalRewardsFetched) return;

      try {
        const data = await turboplayContract.methods
          .totalTokensDistributed()
          .call();
        // console.log("data:", data);
        const totalRewards = data / BigInt(Math.pow(10, DECIMALS));

        // console.log("fetchTotalRewards:", totalRewards);
        commit("setTotalRewards", totalRewards);
      } catch (error) {
        console.error("Failed to fetch total rewards:", error);
      }
    },
  },
  getters: {
    formattedAccount: (state) => {
      if (!state.account) return "";
      const start = state.account.substring(0, 4);
      const end = state.account.substring(state.account.length - 4);
      return `${start}...${end}`;
    },
    formattedBalance: (state) => {
      if (!state.tokenBalance) return 0;
      const formattedBalance = state.tokenBalance.toLocaleString("ko-KR", {
        maximumFractionDigits: 0,
      });
      // console.log("formattedBalance", formattedBalance);
      return formattedBalance;
    },
    formattedTotalRewards: (state) => {
      if (!state.totalRewards) return 0;

      const formattedTotalRewards = state.totalRewards.toLocaleString("ko-KR", {
        maximumFractionDigits: 1,
      });
      // console.log("formattedTotalRewards", formattedTotalRewards);
      return formattedTotalRewards;
    },
    rewardVideoIds: (state) => state.rewardVideoIds,
    totalRewards: (state) => state.totalRewards,
    tokenBalance: (state) => state.tokenBalance,
  },
});
