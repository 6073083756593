export const greatQbot = {
  id: "greatQbot",
  rating: "seven",
  episodes: [
    {
      video_id: "951414168",
      reward: "20",
      duration: 733,
      created_time: "2024-07-08T03:30:29+00:00",
    },
  ],
};
