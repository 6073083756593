import Web3 from "web3";
import Turboplay from "@/contracts/Turboplay.json";
import Token from "@/contracts/Token.json";

const chainId = process.env.VUE_APP_CHAIN_ID;
const chainName = process.env.VUE_APP_CHAIN_NAME;
const rpcUrl = process.env.VUE_APP_RPC_URL;
const blockExplorerUrl = process.env.VUE_APP_BLOCK_EXPLORER_URL;

let web3;

if (window.ethereum) {
  web3 = new Web3(window.ethereum);
  try {
    window.ethereum.enable();
  } catch (error) {
    console.error("User denied account access");
  }
} else if (window.web3) {
  web3 = new Web3(window.web3.currentProvider);
} else {
  web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));
}

const turboplayContract = new web3.eth.Contract(
  Turboplay.abi,
  process.env.VUE_APP_TURBOPLAY_CONTRACT_ADDRESS
);
const tokenContract = new web3.eth.Contract(
  Token.abi,
  process.env.VUE_APP_TOKEN_CONTRACT_ADDRESS
);

export {
  web3,
  turboplayContract,
  tokenContract,
  chainId,
  chainName,
  rpcUrl,
  blockExplorerUrl,
};
