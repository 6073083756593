<template>
  <div class="container">
    <section class="main">
      <img class="bg" src="@/assets/img/main-bg.png" alt="" />

      <div class="info">
        <h1>{{ $t("logo") }}</h1>
        <p>{{ $t("w2e") }}</p>
        <div class="wrapper">
          <div class="box mb-3">
            <p>{{ $t("views") }}:&nbsp;</p>
            <h2>{{ views }}</h2>
          </div>
          <div class="box">
            <p>{{ $t("accumulatedRewards") }}:&nbsp;</p>
            <h2>{{ formattedTotalRewards }} TBC</h2>
          </div>
        </div>
      </div>
      <MainSlideBanner class="big" />
    </section>

    <section class="sub">
      <div>
        <p>{{ $t("claimReward") }}</p>
        <img src="@/assets/img/box.png" alt="" />
      </div>
    </section>

    <section class="small">
      <MainSlideBanner />
    </section>

    <section class="contents-brief">
      <h2>{{ $t("highQualityContent") }}</h2>
      <div class="contents-card-wrapper">
        <router-link to="/animation/greatQbot">
          <div class="content-card">
            <div class="poster">
              <img src="@/assets/img/greatQbot/poster.png" alt="" />
            </div>
            <div class="content-info">
              <div class="title">{{ $t("greatQbot.title") }}</div>
              <div class="des">{{ $t("greatQbot.description") }}</div>
            </div>
          </div>
        </router-link>
        <router-link to="/">
          <div class="content-card">
            <div class="poster">
              <img src="@/assets/img/capola/poster.jpg" alt="" />
            </div>
            <div class="content-info">
              <div class="title">{{ $t("capola.title") }}</div>
              <div class="des">{{ $t("capola.description") }}</div>
            </div>
          </div>
        </router-link>
        <router-link to="/">
          <div class="content-card">
            <div class="poster">
              <img src="@/assets/img/superCute/poster.png" alt="" />
            </div>
            <div class="content-info">
              <div class="title">{{ $t("superCute.title") }}</div>
              <div class="des">
                {{ $t("superCute.description") }}
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </section>

    <section class="exchange">
      <div class="wrapper">
        <p>{{ $t("tradeRewards") }}</p>
        <div class="buttons">
          <a
            href="https://www.digifinex.com/en-ww/trade/USDT/TBC"
            target="_blank"
            class="btn btn-primary"
          >
            {{ $t("tradeOnDIGIFINEX") }}
          </a>
          <a
            href="https://www.xt.com/en/trade/tbc_usdt"
            target="_blank"
            class="btn btn-primary"
          >
            {{ $t("tradeOnXT") }}
          </a>
        </div>
      </div>
      <img src="@/assets/img/exchange.png" alt="" />
    </section>

    <AppFooter color="white"></AppFooter>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AppFooter from "@/components/AppFooter.vue";
import MainSlideBanner from "@/components/MainSlideBanner.vue";

export default {
  name: "MainPage",
  components: {
    AppFooter,
    MainSlideBanner,
  },

  computed: {
    ...mapState(["views"]),
    ...mapGetters(["formattedTotalRewards"]),
  },
  created() {
    this.fetchViews();
    this.fetchTotalRewards();
  },
  methods: {
    ...mapActions(["fetchViews", "fetchTotalRewards"]),
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  display: flex;
  justify-content: space-between;

  .bg {
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: -99;
    margin-left: calc(50% - 50vw);
  }
  .info {
    margin: 5em 0;
    width: 48%;
    h1 {
      color: black;
      font-weight: 800;
      font-size: 60px;
    }
    p {
      font-weight: 500;
      font-size: 28px;
    }
    .wrapper {
      margin: 2em 0 0;
      width: max-content;
      max-width: 100%;
      .box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5em 2.5em;
        height: 5em;
        background-color: white;
        border-radius: 1.5em;
        box-shadow: 0px 0px 12px 0px rgba(40, 29, 112, 0.2);
        width: 100%;
        h2 {
          width: fit-content;
          padding: 0 1em;
          margin: 0 auto;
          text-align: center;
          font-weight: 700;
          font-size: 40px;
          color: #0093ba;
        }
        p {
          width: fit-content;
          color: #777777;
          font-weight: 600;
          font-size: 24px;
        }
      }
    }
  }
}

.sub {
  div {
    display: flex;
    background-color: #0093ba;
    color: white;
    font-weight: 500;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}

.contents-brief {
  padding: 5em 0;
  h2 {
    color: black;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 0.5em;
  }
  .contents-card-wrapper {
    display: grid;
    grid-gap: 2.5em;
    grid-template-columns: repeat(3, 1fr);
    .content-card {
      /* aspect-ratio: 0.97 / 1; */
      height: 450px;
      background-color: white;
      border-radius: 30px;
      box-shadow: 0px 0px 12px 0px rgba(40, 29, 112, 0.2);
      .poster {
        border-radius: 30px 30px 0 0;
        width: 100%;
        height: 65%;
        position: relative;
        display: grid;
        place-items: center;
        overflow: hidden;
        img {
          position: absolute;
          height: 100%;
        }
      }
      .content-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 85%;
        height: 35%;
        margin: auto;
        .title {
          font-weight: 700;
          font-size: 24px;
          color: black;
        }
        .des {
          font-weight: 500;
          font-size: 18px;
          color: #2d2d2d;
          word-break: keep-all;
        }
      }
    }
  }
}

.exchange {
  padding: 0 0 5em;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  .wrapper {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      font-size: 36px;
      font-weight: 700;
      word-break: keep-all;
      margin-bottom: 0.5em;
    }
    .buttons {
      display: grid;
      grid-gap: 1em;
      grid-template-columns: repeat(2, 1fr);
      .btn {
        font-size: 18px;
        font-weight: 600;
        width: 11em;
        line-height: 2em;
      }
    }
  }
  img {
    width: 35%;
  }
}
@media (max-width: 1291px) {
  .main {
    .info {
      h1 {
        font-size: 52px;
      }
      p {
        font-size: 20px;
      }
      .wrapper {
        .box {
          height: 4em;

          h2 {
            font-size: 30px;
          }
          p {
            font-size: 20px;
          }
        }
      }
    }
  }

  .contents-brief {
    .contents-card-wrapper {
      .content-card {
        .content-info {
          .title {
            font-size: 20px;
          }
          .des {
            font-size: 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .main {
    .info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 3.5em 0;
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 12px;
      }
      .wrapper {
        .box {
          border-radius: 0.7em;
          padding: 0.5em 1.5em;
          height: 3em;

          h2 {
            font-size: 16px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
  .sub {
    div {
      font-size: 10px;
      padding: 0 2em;
      img {
        width: 6.8em;
      }
    }
  }
  .contents-brief {
    padding: 3em 0;
    width: fit-content;
    margin: 0 auto;
    h2 {
      font-size: 20px;
      margin-bottom: 0.5em;
      width: 250px;
    }
    .contents-card-wrapper {
      grid-template-columns: repeat(1, 1fr);
      .content-card {
        height: 310px;
        border-radius: 20px;
        max-width: 256px;
        .poster {
          border-radius: 20px 20px 0 0;
        }
        .content-info {
          .title {
            font-size: 16px;
          }
          .des {
            font-size: 12px;
          }
        }
      }
    }
  }
  .exchange {
    padding: 0 0 6.5em;
    position: relative;
    flex-direction: column;
    align-items: center;
    max-width: 255px;
    .wrapper {
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        font-size: 20px;
        margin-bottom: 0.5em;
      }
      .buttons {
        position: absolute;
        bottom: 3.5em;
        width: 100%;

        .btn {
          border-radius: 0.7em;
          font-size: 11px;
          padding: 0.5em;
        }
      }
    }
    img {
      width: 218px;
    }
  }
}
</style>
