<template>
  <nav class="navbar navbar-expand-lg">
    <div class="loading" v-if="loading">
      <LoadingModal @click="loading = false" />
    </div>
    <div class="container-xxl">
      <router-link class="navbar-brand me-4" to="/">
        <img src="@/assets/img/logo.png" alt="" />
      </router-link>

      <div class="d-flex">
        <div class="d-flex align-items-center">
          <button
            v-if="!account"
            @click="callWallectConnect()"
            class="btn btn-light small"
            type="button"
          >
            {{ $t("connectWallet") }}
          </button>
        </div>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarOffcanvasLg"
          aria-controls="navbarOffcanvasLg"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div
        class="offcanvas offcanvas-end offcanvas-custom"
        tabindex="-1"
        id="navbarOffcanvasLg"
        aria-labelledby="navbarOffcanvasLgLabel"
      >
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn-close ms-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div class="offcanvas-body">
          <ul class="navbar-nav me-auto mb-lg-0">
            <li
              class="nav-item dropdown"
              :class="{ disabled: animations.length === 0 }"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="animationDropdown"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="animations.length !== 0"
              >
                {{ $t("animation") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="animationDropdown">
                <li v-for="animation in animations" :key="animation.id">
                  <div
                    class="dropdown-item"
                    data-bs-dismiss="offcanvas"
                    @click="closeSideBar(`/animation/${animation.id}`)"
                  >
                    {{ $t(`${animation.id}.title`) }}
                  </div>
                </li>
              </ul>
            </li>

            <li
              class="nav-item dropdown"
              :class="{ disabled: movies.length === 0 }"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="movieDropdown"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="movies.length !== 0"
              >
                {{ $t("movie") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="movieDropdown">
                <li v-for="movie in movies" :key="movie.id">
                  <div
                    class="dropdown-item"
                    data-bs-dismiss="offcanvas"
                    @click="closeSideBar(`/movie/${movie.id}`)"
                  >
                    {{ $t(`movies.${$t(movie.id)}.title`) }}
                  </div>
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown disabled">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="movieDropdown"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="movies.length !== 0"
              >
                {{ $t("game") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="gameDropdown"></ul>
            </li>

            <li
              class="nav-item dropdown"
              :class="{ disabled: documentaries.length === 0 }"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="docuDropdown"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="documentaries.length !== 0"
              >
                {{ $t("documentary") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="docuDropdown">
                <li v-for="documentary in documentaries" :key="documentary.id">
                  <div
                    class="dropdown-item"
                    data-bs-dismiss="offcanvas"
                    @click="closeSideBar(`/documentary/${documentary.id}`)"
                  >
                    {{ $t(`documentarys.${$t(documentary.id)}.title`) }}
                  </div>
                </li>
              </ul>
            </li>

            <li
              class="nav-item dropdown"
              :class="{ disabled: events.length === 0 }"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="eventDropdown"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="events.length !== 0"
              >
                {{ $t("event") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="eventDropdown">
                <li v-for="event in events" :key="event.id">
                  <div
                    class="dropdown-item"
                    data-bs-dismiss="offcanvas"
                    @click="closeSideBar(`/event/${event.id}`)"
                  >
                    {{ $t(`events.${$t(event.id)}.title`) }}
                  </div>
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="communityDropdown"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="false"
              >
                {{ $t("aboutUs") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="communityDropdown">
                <li>
                  <a
                    class="dropdown-item"
                    href="https://turbochain.world"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ $t("projectHomepage") }}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://asiastar.co.kr"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $t("foundationHomepage") }}</a
                  >
                </li>
                <li>
                  <a
                    v-if="currentLanguage == 'EN'"
                    class="dropdown-item"
                    href="https://drive.google.com/file/d/1huzo2RNmmsnkSGIoau7qrESUkEOpLcC_/view?usp=drive_link"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $t("whitepaper") }}</a
                  >
                  <a
                    v-else
                    class="dropdown-item"
                    href="https://drive.google.com/file/d/1nkqQrrK-GZWXJ2bd_t02EdLXdFT2_rix/view?usp=drive_link"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $t("whitepaper") }}</a
                  >
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="communityDropdown"
                role="button"
                data-bs-toggle="dropdown"
                :aria-expanded="false"
              >
                {{ $t("community") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="communityDropdown">
                <li>
                  <a
                    class="dropdown-item"
                    href="https://x.com/Turbochain_TBC"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    X</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://t.me/turbochainworld"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $t("telegram") }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="additional-menu">
          <div class="account-info" v-if="account">
            <div
              class="btn btn-light"
              @click="openWallectModal()"
              data-bs-dismiss="offcanvas"
            >
              {{ formattedAccount }}
            </div>
            <div>
              <img
                src="@/assets/img/wallet.png"
                @click="addTokenToMetamaskHandler"
                alt="Wallet Icon"
                class="wallet-icon"
              />
              <span
                ><i class="fas fa-wallet"></i> {{ formattedBalance }} TBC
              </span>
            </div>
          </div>

          <button
            v-else
            @click="callWallectConnect()"
            class="btn btn-light"
            type="button"
            data-bs-dismiss="offcanvas"
          >
            {{ $t("connectWallet") }}
          </button>

          <div class="ms-3 dropdown language-wrapper">
            <div
              class="dropdown-toggle"
              type="button"
              id="languageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ currentLanguage }}
              <img src="@/assets/img/dropdown.png" alt="" />
            </div>

            <ul class="dropdown-menu" aria-labelledby="languageDropdown">
              <li>
                <a class="dropdown-item" href="#" @click="changeLanguage('en')"
                  >EN</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="changeLanguage('ko')"
                  >KO</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { animations } from "@/data/animations/index.js";
import { movies } from "@/data/movies/index.js";
import { documentaries } from "@/data/documentaries/index.js";
import { events } from "@/data/events/index.js";
import { addTokenToMetamask } from "@/utils/web3/metamask";
import { projectId, config } from "@/utils/web3/config";
import LoadingModal from "./LoadingModal.vue";

import {
  createWeb3Modal,
  useWeb3Modal,
  useWeb3ModalState,
} from "@web3modal/wagmi/vue";

import { reconnect, getAccount } from "@wagmi/core";

export default {
  name: "AppHeader",
  components: {
    LoadingModal,
  },
  data() {
    return {
      animations,
      movies,
      documentaries,
      events,
      currentLanguage: "KO",
      address: "",
      uri: "",
      interA: null,
      loading: false,
      // modal: useWeb3Modal(),
    };
  },
  computed: {
    ...mapState(["account"]),
    ...mapGetters(["formattedAccount", "formattedBalance"]),
  },
  methods: {
    ...mapActions(["connectWallet"]),
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.currentLanguage = lang.toUpperCase();
    },
    setupMetamaskListeners() {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", () => {
          window.location.reload();
        });

        window.ethereum.on("chainChanged", () => {
          window.location.reload();
        });
      }
    },
    async addTokenToMetamaskHandler() {
      await addTokenToMetamask();
    },
    closeSideBar(url) {
      this.$router.push(url);
    },
    async callWallectConnect() {
      const { open } = useWeb3Modal();
      open();
      this.loading = true;
      useWeb3ModalState();

      this.interA = setInterval(() => {
        const account = getAccount(config);
        if (account.isConnected) {
          clearInterval(this.interA);
          this.connectWallet(account.address);
          this.loading = false;
        }
      }, 1000);
    },

    openWallectModal() {
      const { open } = useWeb3Modal();
      open();

      this.interA = setInterval(() => {
        const state = useWeb3ModalState();
        if (!state.open) {
          clearInterval(this.interA);
          const account = getAccount(config);
          if (!account.isConnected) {
            window.location.reload();
          }
        }
      }, 1000);
    },
  },
  created() {
    this.setupMetamaskListeners();

    reconnect(config);

    // 3. Create modal
    createWeb3Modal({
      wagmiConfig: config,
      projectId,
      enableAnalytics: false, // Optional - defaults to your Cloud configuration
      enableOnramp: false, // Optional - false as default
    });
  },
};
</script>

<style lang="scss" scoped>
.offcanvas {
  --bs-offcanvas-width: 250px;
}
.navbar {
  .dropdown-toggle::after {
    display: none;
  }

  .disabled > .nav-link {
    pointer-events: none;
  }
  .btn-light {
    background-color: #f7f7f7;
    border-color: #636363;
    color: #636363;
    font-size: 16px;
    font-weight: 500;
    border-radius: 2em;
    padding: 0.5em 1.5em;
  }
  .offcanvas-custom {
    justify-content: space-between;
    flex-direction: row;
    .dropdown-menu {
      border: none;
      box-shadow: 0px 0px 12px 0px rgba(40, 29, 112, 0.2);
      --bs-dropdown-link-active-bg: white;
      .dropdown-item {
        color: #636363;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .navbar-nav {
      .nav-link {
        color: #3e3a39;
        font-size: 20px;
        font-weight: 600;
        margin: 0 0.3em 0;
      }
    }
    .additional-menu {
      display: flex;
      .account-info {
        display: flex;
        align-items: center;
        div {
          display: flex;
          align-items: center;
          span {
            font-size: 20px;
            font-weight: 700;
            color: #0093ba;
          }
          .wallet-icon {
            margin-left: 1em;
            margin-right: 0.3em;
            cursor: pointer;
          }
        }
      }
      .language-wrapper {
        display: grid;
        place-items: center;
        .dropdown-menu {
          min-width: 6.5rem;
          left: -80%;
        }
        #languageDropdown {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: 1291px) {
  .navbar {
    .btn-light {
      font-size: 12px;
      padding: 0.5em 0.7em;
    }
    .navbar-brand {
      img {
        height: 1.9em;
      }
    }
    .offcanvas-custom {
      .dropdown-menu {
        .dropdown-item {
          font-size: 14px;
        }
      }
      .navbar-nav {
        .nav-link {
          font-size: 16px;
          margin: 0;
        }
      }
      .additional-menu {
        .account-info {
          div {
            span {
              font-size: 16px;
            }
            .wallet-icon {
              width: 1.2em;
            }
          }
        }
        .language-wrapper {
          #languageDropdown {
            font-size: 16px;
            img {
              width: 1.2em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .navbar {
    .btn-light {
      font-size: 12px;
      border-radius: 2em;
    }

    .navbar-brand {
      img {
        height: 1.6em;
      }
    }
    .navbar-toggler {
      .navbar-toggler-icon {
        width: 1em;
      }
      border: none;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .offcanvas-custom {
      flex-direction: column;
      .offcanvas-header {
        z-index: 999;
      }
      .offcanvas-body {
        margin-top: 7em;

        .nav-link {
          color: #636363;
          font-size: 16px;
          margin: 0 0.3em 0;
          font-weight: 500;
        }
      }
      .dropdown-menu {
        box-shadow: none;
        padding-top: 0;
        li {
          &:first-child {
            .dropdown-item {
              border-radius: 0.6em 0.6em 0 0;
              padding-top: 0.7em;
            }
          }
          &:last-child {
            .dropdown-item {
              padding-bottom: 0.7em;
              border-radius: 0 0 0.6em 0.6em;
            }
          }
          .dropdown-item {
            color: #3e3a39;
            background-color: #f7f7f7;

            font-size: 16px;
          }
        }
      }

      .additional-menu {
        position: absolute;
        top: 0;
        height: 10rem;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #cccccc;
        .account-info {
          flex-direction: column;

          div {
            margin-top: 0.5em;
            span {
              font-size: 14px;
            }
            .wallet-icon {
              width: 1em;
              margin-left: 0;
            }
          }
        }
        .language-wrapper {
          position: absolute;
          bottom: 1rem;
          right: 2rem;
          .dropdown-menu {
            min-width: 6.5rem;
            left: -150%;
          }
          #languageDropdown {
            font-size: 14px;
            img {
              width: 1em;
            }
          }
        }
      }
    }
  }
}
</style>
