<template>
  <div class="banner">
    <b-carousel
      id="carousel-example"
      ride="carousel"
      controls
      indicators
      :interval="4000"
    >
      <!-- 이미지 슬라이드 -->
      <b-carousel-slide
        v-for="n in imageCount"
        :key="n"
        :class="{ active: n === 1 }"
        :img-src="require(`@/assets/img/banner/banner${n}.png`)"
        @click="goMovie(n)"
      >
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
<script>
export default {
  name: "MainSlideBanner",
  data() {
    return {
      imageCount: 2, // 실제 이미지 수에 맞게 조정
    };
  },
  methods: {
    goMovie(num) {
      if (num === 1) return;
      this.$router.push("/animation/greatQbot");
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  height: 312px;
  width: 480px;
  margin: auto 0;
}
@media (max-width: 991px) {
  .banner {
    height: 166px;
    width: 255px;
    margin: 3em auto 0;
  }
}
</style>
